import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SignUpDataService {
  private userData = new BehaviorSubject<any>({});
  currentUser: any = this.userData.asObservable();

  updateUser(data: any) {
    this.userData.next(data);
  }
}
